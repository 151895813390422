/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p",
    strong: "strong",
    img: "img",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {TableOfContents, SideBySide, LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!SideBySide) _missingMdxReference("SideBySide", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "causes-symptoms-and-treatments",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#causes-symptoms-and-treatments",
    "aria-label": "causes symptoms and treatments permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Causes, symptoms, and treatments"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "The Usher syndrome is a condition characterized by hearing loss and vision impairment."), " The retina experiences degenerative changes and can occur simultaneously with hearing loss. In the following section, you will learn more about this condition and the available treatment option – hearing aids – that can counteract hearing loss."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The following contains information about Usher syndrome. You will learn about possible causes, signs and symptoms, and possible treatments."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "cause-of-the-problems",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#cause-of-the-problems",
    "aria-label": "cause of the problems permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Cause of the problems"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "A loss of hearing occurs through injury of hair cells in the inner ear. Normally, these hair cells are put in motion through mechanical signals and thereby produce nerve activity. Damage to the hair cells does not allow, or only allows, insufficient transduction of the signal: Inner ear hearing loss and deafness is the result. The visual impairment is caused by a degeneration of the retina when individual photoreceptors (retinitis pigmentosa) are destroyed. It proceeds from the outer to the inner field of vision. The term pigmentosa is derived from the observation that the clinical picture of the disorder includes pigment inclusions into the ocular fundus."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/combination-of-deafness-and-blindness-usher-syndrome.jpg",
    alt: "Combination of deafness and blindness",
    className: "c-md-img"
  })), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "different-types-of-the-usher-syndrome",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#different-types-of-the-usher-syndrome",
    "aria-label": "different types of the usher syndrome permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Different types of the Usher syndrome"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The ", React.createElement(_components.strong, null, "Usher syndrome comes in three distinct types"), " that are differentiated by the onset of the disease and its manifestation:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "With Usher type 1, deafness occurs at birth while the visual impairment develops in the course of childhood. Often, this form of the disease is accompanied with vestibular disorders."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Type 2 of the Usher syndrome usually presents a severe hearing impairment which, however, does not worsen over the course of the disease. Moreover, the degeneration of the retina is observed during puberty or early adulthood."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Usher type 3 is characterized by a gradual hearing loss with late-onset of visual impairment."), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/usher-syndrom-type.jpg",
    alt: "Usher types",
    className: "c-md-img"
  })), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "symptoms",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#symptoms",
    "aria-label": "symptoms permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Symptoms"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/usher-syndrom-in-US-frame.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "Approximately ", React.createElement(_components.strong, null, "50,000 people in the U.S. have Usher syndrome,"), " and the number continues to increase with age. A possible explanation for this is that congenital deafness or a hearing impairment is often diagnosed at first, but the symptoms of retinitis pigmentosa are not recognized until later. However, early diagnosis is important in order to attenuate the far-reaching consequences of this condition and to take preventative measures. With the constant ", React.createElement(_components.a, {
    href: "/hearing-loss",
    className: "c-md-a"
  }, "worsening of a hearing impairment"), ", learning sign language is a good way to keep enjoying life despite apparent deafness.")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "This is, of course, only possible when there is no, or just minor, visual impairment. However, the fact is that retinitis pigmentosa is often discovered very late. At first, it is noticeable when a person’s vision seems impaired at dawn and at night. (The necessary photoreceptors are located at the periphery of the retina and thus affected first.) Moreover, patients notice an increased sensitivity to glare, and contrasts cannot be adequately perceived. As the illness progresses, a type of tunnel vision develops due to a restricted field of vision. Therefore, the task of reading does not pose any problems yet, but the sense of orientation in a room becomes difficult."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The hearing impairment depends on the respective type. Congenital deafness with type 1 is characterized by a complete loss of hearing. No form of sound can be perceived, and those who are affected resort to lip reading or American Tactile Sign Language (TSL). The other types of Usher syndrome are usually characterized by a severe hearing disability in the high-frequency range and a moderate hearing impairment in the low-frequency range. The situation is aggravated by the problem of what is typically referred to as “discrimination loss.” This means that sounds at a certain volume are still audible, but language at an equal volume cannot be discerned anymore."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "treatment-options",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#treatment-options",
    "aria-label": "treatment options permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Treatment options"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Currently, there are only insufficient and experimental treatment options available for the degeneration of the retina. They all aim at delaying the complete destruction of the photoreceptors. In the case of complete deafness, as with type 1 Usher syndrome, a cochlear implant can be considered, especially with children. As an artificial inner ear replacement, it enables the direct transduction of the acoustic signal to the auditory nerve."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "By contrast, the reduced hearing ability with type 2 Usher syndrome can be effectively treated with ", React.createElement(_components.a, {
    href: "/hearing-aids/types/binaural",
    className: "c-md-a"
  }, "binaural hearing aids"), ", which amplify residual hearing. Hence, it is very important that ", React.createElement(_components.a, {
    href: "/about-us/network",
    className: "c-md-a"
  }, "an experienced hearing specialist select the appropriate hearing system"), " and perform an optimal fitting. With the right hearing aids, patients suffering from Usher syndrome will be enabled to lead a more fulfilling life, rich in sounds."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/user-syndrom-pigmentosa.jpg",
    alt: "User syndrom pigmentosa",
    className: "c-md-img"
  })), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "How hearing aids can help with Usher syndrome…")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "With Usher syndrome type 2, binaural hearing aids can treat hearing loss by actively supporting a person’s existing hearing capacity. The use of hearing aids helps to integrate affected individuals into their environment and thus prevents them from sliding into social isolation. The sooner hearing aids are used, the more likely it is that residual hearing can be salvaged and preserved."), "\n", React.createElement(LandingPageCta, {
    copy: "Start No-risk Trial",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
